<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openSalesDeliveryItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="salesDelivery.status === DELIVERY_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("SALES_DELIVERIES.ADD_SALES_DELIVERY_ITEM") }}
        </base-button>
      </div>
    </div>

    <sales-delivery-view-details-sales-delivery-item-table
      :salesDelivery="salesDelivery"
      @onOpenSalesDeliveryItemModal="openSalesDeliveryItemModal"
    />

    <div class="row mb-5" v-if="salesDelivery.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesDelivery.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="salesDelivery.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesDelivery.pricing.discounts.total * -1) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in salesDelivery.pricing.discounts
                .details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(discount.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0" v-if="salesDelivery.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesDelivery.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in salesDelivery.pricing.taxes.details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(tax.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(salesDelivery.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <sales-delivery-view-details-sales-delivery-item-form
      :salesDelivery="salesDelivery"
      :salesDeliveryItem="salesDeliveryItem"
      :showModal="showModal"
      @onCloseSalesDeliveryItemModal="closeSalesDeliveryItemModal"
      @onSubmitSalesDeliveryItemForm="handleSalesDeliveryItemFormSubmit"
      @onDeleteSalesDeliveryItem="deleteSalesDeliveryItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import {
  DELIVERY_STATUS_CANCELED,
  DELIVERY_STATUS_DRAFT,
} from "@/constants/deliveries";
import SalesDeliveryViewDetailsSalesDeliveryItemForm from "./SalesDeliveryViewDetailsSalesDeliveryItemForm.vue";
import SalesDeliveryViewDetailsSalesDeliveryItemTable from "./SalesDeliveryViewDetailsSalesDeliveryItemTable.vue";
import defaultSalesDeliveryItem from "../defaultSalesDeliveryItem";

export default {
  name: "sales-delivery-view-details",

  components: {
    SalesDeliveryViewDetailsSalesDeliveryItemForm,
    SalesDeliveryViewDetailsSalesDeliveryItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["salesDelivery"],

  data() {
    return {
      salesDeliveryItem: cloneDeep(defaultSalesDeliveryItem),
      loading: false,
      showModal: false,
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_CANCELED: DELIVERY_STATUS_CANCELED,
    };
  },

  computed: {},

  methods: {
    openSalesDeliveryItemModal(salesDeliveryItem = null) {
      if (!salesDeliveryItem) {
        this.salesDeliveryItem = cloneDeep(defaultSalesDeliveryItem);
      } else {
        this.salesDeliveryItem = cloneDeep(salesDeliveryItem);
      }
      this.showModal = true;
    },

    closeSalesDeliveryItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handleSalesDeliveryItemFormSubmit(salesDeliveryItemData) {
      if (!salesDeliveryItemData.id) {
        this.addSalesDeliveryItem(salesDeliveryItemData);
      } else {
        this.editSalesDeliveryItem(salesDeliveryItemData);
      }
    },

    async addSalesDeliveryItem(salesDeliveryItemData) {
      this.loading = true;
      try {
        salesDeliveryItemData.salesDelivery.id = this.salesDelivery.id;

        await this.$store.dispatch(
          "salesDeliveryItems/add",
          salesDeliveryItemData
        );
        this.$emit("salesDeliveryItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async editSalesDeliveryItem(salesDeliveryItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "salesDeliveryItems/update",
          salesDeliveryItemData
        );
        this.$emit("salesDeliveryItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async deleteSalesDeliveryItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("salesDeliveryItems/destroy", id);
        this.$emit("salesDeliveryItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
