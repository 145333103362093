<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <!-- <base-input
        :label="`${$t('SALES_DELIVERIES.ITEM_TYPE')}`"
        v-if="!salesDeliveryItem.id"
      >
        <el-select
          name="Type"
          v-model="salesDeliveryItemModel.salesDeliverable.type"
          prepend-icon="fas fa-calendar"
          @change="changeSalesDeliveryableType"
        >
          <el-option :value="null" :label="$t('COMMON.NONE')" />
          <el-option
            v-if="salesDelivery.organization"
            value="products"
            :label="$t('COMMON.PRODUCT')"
          />
          <el-option
            v-if="
              !$currentUserIsLevelOrganization() &&
              !salesDelivery.organization
            "
            value="packages"
            :label="$t('COMMON.PACKAGE')"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.salesDeliverable" /> -->

      <div>
        <base-input
          v-if="salesDeliveryItemModel.salesDeliverable.type == 'products'"
          :label="`${$t(`COMMON.PRODUCT`)} (*)`"
        >
          <product-selector
            :filterOrganization="salesDelivery.organization.id"
            :product="salesDeliveryItemModel.salesDeliverable.id"
            :allowNone="false"
            :showAll="false"
            @productChanged="
              (productId, product) => {
                salesDeliveryItemModel.salesDeliverable.id = productId;
                salesDeliveryItemModel.code = product.code;
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.salesDeliverable" />
      </div>

      <base-input
        :label="`${$t('COMMON.CODE')} (*)`"
        :placeholder="$t('COMMON.CODE')"
        v-model="salesDeliveryItemModel.code"
        input-classes="form-control-alternative"
        v-if="!salesDeliveryItemModel.salesDeliverable.type"
      >
      </base-input>
      <validation-error
        v-if="!salesDeliveryItemModel.salesDeliverable.type"
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="salesDeliveryItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <base-input
        :label="`${$t('COMMON.QUANTITY')} (*)`"
        :placeholder="$t('COMMON.QUANTITY')"
        v-model="salesDeliveryItemModel.quantity"
        type="number"
        step="1"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.quantity" />
    </form>

    <template slot="footer">
      <button
        v-if="!salesDeliveryItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitSalesDeliveryItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_DELIVERIES.ADD_SALES_DELIVERY_ITEM") }}
      </button>

      <base-button
        v-if="salesDeliveryItemModel.id"
        native-type="submit"
        type="primary"
        class="new-sales-delivery-item--add"
        @click="submitSalesDeliveryItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_DELIVERIES.UPDATE_SALES_DELIVERY_ITEM") }}
      </base-button>

      <base-button
        v-if="salesDeliveryItemModel.id"
        type="danger"
        @click="deleteSalesDeliveryItem(salesDeliveryItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_DELIVERIES.DELETE_SALES_DELIVERY_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeSalesDeliveryItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ProductSelector from "@/components/ProductSelector.vue";
import defaultSalesDeliveryItem from "../defaultSalesDeliveryItem";

export default {
  name: "sales-delivery-view-details-sales-delivery-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["salesDelivery", "salesDeliveryItem", "showModal", "loading"],

  data() {
    return {
      all_salesDeliverables: [],
      salesDeliveryItemModel: cloneDeep(defaultSalesDeliveryItem),
    };
  },

  computed: {},

  methods: {
    closeSalesDeliveryItemModal() {
      this.$emit("onCloseSalesDeliveryItemModal", true);
    },

    submitSalesDeliveryItemForm() {
      const salesDeliveryItemData = cloneDeep(this.salesDeliveryItemModel);
      salesDeliveryItemData.unit_price = parseFloat(
        salesDeliveryItemData.unit_price
      );
      salesDeliveryItemData.quantity = parseInt(
        salesDeliveryItemData.quantity,
        10
      );
      salesDeliveryItemData.discount = parseFloat(
        salesDeliveryItemData.discount
      );
      if (!salesDeliveryItemData.salesDeliverable.id) {
        salesDeliveryItemData.salesDeliverable = null;
      }

      this.$emit("onSubmitSalesDeliveryItemForm", salesDeliveryItemData);
    },

    deleteSalesDeliveryItem(salesDeliveryItemId) {
      this.$emit("onDeleteSalesDeliveryItem", salesDeliveryItemId);
    },

    async getSalesDeliveryables(type) {
      try {
        let params = { sort: "name" };

        params = {
          ...params,
          filter: { organization: this.salesDelivery.organization.id },
        };
        await this.$store.dispatch(type + "/list", params);
        this.all_salesDeliverables = await this.$store.getters[
          type + "/dropdown"
        ];
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async changeSalesDeliveryableType(type) {
      if (!type) {
        return;
      }
      await this.getSalesDeliveryables(type);
      this.salesDeliveryItemModel.salesDeliverable.id = null;
    },

    async changeSalesDeliveryable(id) {
      const salesDeliverables = await this.$store.getters[
        this.salesDeliveryItemModel.salesDeliverable.type + "/list"
      ];
      const salesDeliverable = salesDeliverables.find((item) => item.id === id);
      this.salesDeliveryItemModel.unit_price = salesDeliverable.price;

      this.salesDeliveryItemModel.code = salesDeliverable.code;
      this.salesDeliveryItemModel.excerpt = salesDeliverable.denomination;
      this.salesDeliveryItemModel.taxes = salesDeliverable.taxes;
    },
  },

  mounted() {},

  watch: {
    salesDeliveryItem(salesDeliveryItem) {
      if (salesDeliveryItem) {
        this.salesDeliveryItemModel = cloneDeep(salesDeliveryItem);
      }
      this.resetApiValidation();
    },
  },
};
</script>
