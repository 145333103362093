import defaultTaxes from "@/constants/defaultTaxes";

export default {
  type: "sales-delivery-items",
  code: null,
  excerpt: '',
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["salesDelivery", "salesDeliverable"],
  salesDelivery: {
    type: "sales-deliveries",
    id: null,
  },
  salesDeliverable: {
    type: 'products',
    id: null,
  },
  pricing: {},
  taxes: defaultTaxes,
};
