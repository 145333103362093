<template>
  <div class="row mb-5">
    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="salesDelivery.items"
    >
      <el-table-column :label="$t('COMMON.CODE')" prop="code">
        <template v-slot="{ row }">
          <div v-if="row.salesDeliverable">
            <a
              href="#"
              @click.prevent="goToSalesDeliveryable(row.salesDeliverable)"
            >
              {{ row.code }}<br />
              <div
                class="text-muted sales-delivery-item-excerpt"
                v-html="row.excerpt"
              ></div>
            </a>
          </div>
          <div v-if="!row.salesDeliverable">
            {{ row.code }}<br />
            <div
              class="text-muted sales-delivery-item-excerpt"
              v-html="row.excerpt"
            ></div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.QUANTITY')" prop="quantity" />

      <el-table-column min-width="50px" align="center">
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip
            :content="$t('COMMON.EDIT')"
            placement="top"
            v-if="salesDelivery.status === DELIVERY_STATUS_DRAFT"
          >
            <a
              type="text"
              @click="openSalesDeliveryItemModal(row)"
              class="table-action"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { DELIVERY_STATUS_DRAFT } from "@/constants/deliveries";

export default {
  name: "sales-delivery-view-details-sales-delivery-item-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["salesDelivery"],

  data() {
    return {
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
    };
  },

  computed: {},

  methods: {
    openSalesDeliveryItemModal(salesDeliveryItem) {
      this.$emit("onOpenSalesDeliveryItemModal", salesDeliveryItem);
    },

    async goToSalesDeliveryable(salesDeliverable) {
      this.$router.push(this.$objectViewRoute(salesDeliverable));
    },
  },

  mounted() {},

  watch: {},
};
</script>

<style>
.sales-delivery-item-excerpt p,
.sales-delivery-item-excerpt,
.sales-delivery-item-excerpt * {
  font-size: 0.7rem;
}
</style>